<template>
  <div class="sweepstakes_wrap">
    <div class="act_title" v-if="isLogin==1" style="margin-top: 12rem;">
        <img v-if="isQian==0"  @click="checkQian" src="https://yingzegbw.oss-cn-beijing.aliyuncs.com/eventbg/pc%E7%AD%BE%E5%88%B0%E6%8C%89%E9%92%AE.png" alt="签到按钮" style="width:50%;">
        <img v-else @click="checkQian" src="https://yingzegbw.oss-cn-beijing.aliyuncs.com/eventbg/pc%E8%BF%9E%E7%AD%BE%E5%B7%B2%E7%AD%BE.png" alt="签到完成按钮" style="width:50%;">
        <p v-if="isQian==0" class="qian_detail">今日签到后查看</p>
        <p v-else class="qian_detail">您已连续签到{{signCount}}天，现有{{luckCount}}次抽奖机会</p>
    </div> 
    
     <div v-else class="act_title" style="margin-top: 12rem;">
        <img @click="checkQian" src="https://yingzegbw.oss-cn-beijing.aliyuncs.com/eventbg/pc%E7%AD%BE%E5%88%B0%E6%8C%89%E9%92%AE.png" alt="签到按钮" style="width:50%;">
        <p class="qian_detail">今日签到后查看</p>
    </div>


    <div class="sweepstakes_content">
        <canvas class="Sweepstakes_dibu" id="myCanvas" width="600" height="600" ref="canvas">
            当前浏览器版本过低，请使用其他浏览器尝试
        </canvas>
        <img class="Sweepstakes_start" src="../assets/img/newYear/start.png" id="start">
    </div>
    
    <div class="sweepstakes_bottom">
        <div class="Sweepstakes_guize">
            <div class="Sweepstakes_guize_content">
                <span class="huodong" style="margin-top:0.4rem;">
                    1、活动期间连续签到7天即可抽奖1次。
                </span>
                <span class="huodong">2、活动时间：2022年09月10日-09月30日。</span>
                <span class="huodong">3、活动对象：普天同创新老客户。</span>
                <span class="huodong">4、抽奖礼品兑换后，将在10个工作日内寄出，请耐心等待。</span>
            </div>
        </div>
    </div>
    <red-prize
      v-if="showRedPrize"
      @close="onClosePrize"
      :src="awardImg"
      :awardName="awardName"
      hint="恭喜您获得"
    ></red-prize>
</div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref} from "vue";
import RedPrize from "./RedPrize.vue";
import Modal from "@/components/UI/Modal";
import { useRouter } from "vue-router";
import $ from "jquery";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import qs from "qs";
export default defineComponent({
  name: "qiandao",
  components: {
    RedPrize
  },
  setup() {
    
    const isLogin = ref(0);
    const isQian=ref(0);
    const signCount=ref(0);
    const luckCount=ref(0);

    function getQian(){
      axios.get("/M/Activicy/isToday").then(res => {
        const data = res.data.obj;
        isLogin.value = data.isLogin;
        isQian.value = data.isQian;
        signCount.value = data.signCount;
        luckCount.value = data.luckCount;
      }).catch(err => {
        console.log(err)
      })
    }

    getQian();

    ($.fn as any).WheelSurf = function (options: any) {
        const _default = {
            outerCircle: {
                color: '#df1e15'
            },
            innerCircle: {
                color: '#f4ad26'
            },
            dots: ['#fbf0a9', '#fbb936'],
            disk: ['#ffb933', '#ffe8b5', '#ffb933', '#ffd57c', '#ffb933', '#ffe8b5', '#ffd57c'],
            title: {
                color: '#5c1e08',
                font: '19px Arial'
            }
        }

        $.extend(_default,options)
        // 画布中心移动到canvas中心

        /* eslint-disable */
        const _this = this[0],
            width = _this.width,
            height = _this.height,
            ctx = _this.getContext("2d"),
            imgs: any = [],
            awardTitle: any = [],
            awardPic: any = []
        for (const item in (_default as any).list) {
            awardTitle.push((_default as any).list[item].name)
            imgs.push((_default as any).list[item].image)
        }
        const num = imgs.length
        // 圆心
        const x = width / 2
        const y = height / 2
        ctx.translate(x, y)

        return {
            init: function (angelTo: any) {
                angelTo = angelTo || 0;

                ctx.clearRect(-this.width, -this.height, this.width, this.height);

                // 平分角度
                const angel = (2 * Math.PI / 360) * (360 / num);
                let startAngel = 2 * Math.PI / 360 * (-90)
                let endAngel = 2 * Math.PI / 360 * (-90) + angel

                // 旋转画布
                ctx.save()
                ctx.rotate(angelTo * Math.PI / 180);
                // 画外圆
                ctx.beginPath();
                ctx.lineWidth = 25;
                ctx.strokeStyle = _default.outerCircle.color;
                ctx.arc(0, 0, 243, 0, 2 * Math.PI)
                ctx.stroke();
                // 画里圆
                ctx.beginPath();
                ctx.lineWidth = 23;
                ctx.strokeStyle = _default.innerCircle.color;
                ctx.arc(0, 0, 218, 0, 2 * Math.PI)
                ctx.stroke();

                // 装饰点
                const dotColor = _default.dots
                for (let i = 0; i < 12; i++) {
                    // 装饰点 圆心 坐标计算
                    ctx.beginPath();
                    const radius = 230;
                    const xr = radius * Math.cos(startAngel)
                    const yr = radius * Math.sin(startAngel)

                    ctx.fillStyle = dotColor[i % dotColor.length]
                    ctx.arc(xr, yr, 11, 0, 2 * Math.PI)
                    ctx.fill()

                    startAngel += (2 * Math.PI / 360) * (360 / 12);

                }
                // 画里转盘                
                const colors = _default.disk
                for (let i = 0; i < num; i++) {
                    ctx.beginPath();
                    ctx.lineWidth = 208;
                    ctx.strokeStyle = colors[i % colors.length]
                    ctx.arc(0, 0, 104, startAngel, endAngel)
                    ctx.stroke();
                    startAngel = endAngel
                    endAngel += angel
                }
                // 添加奖品
                function loadImg() {

                    const dtd = $.Deferred()
                    let countImg = 0
                    if (awardPic.length) {
                        return dtd.resolve(awardPic);
                    }
                    for (let i = 0; i < num; i++) {
                        const img = new Image()
                        awardPic.push(img)

                        img.src = imgs[i]
                        img.onload = function () {
                            countImg++
                            if (countImg == num) {
                                dtd.resolve(awardPic);
                            }
                        }
                    }
                    return dtd.promise()
                }

                $.when(loadImg()).done(function (awardPic) {

                    startAngel = angel / 2
                    for (let i = 0; i < num; i++) {
                        ctx.save();
                        ctx.rotate(startAngel)
                        ctx.drawImage(awardPic[i], -36, -23 - 130, 70, 70);
                        ctx.font = _default.title.font;
                        ctx.fillStyle = _default.title.color
                        ctx.textAlign = "center";
                        ctx.fillText(awardTitle[i], 0, -170);
                        startAngel += angel
                        ctx.restore();
                    }
                })
                ctx.restore();
            },
            /**
             * @param angel 旋转角度
             * @param callback 转完后的回调函数
             */
            lottery: function (angel: any, callback: any) {
                angel = angel || 0
                angel = 360-angel
                angel += 720
                // 基值（减速）
                const baseStep = 30
                // 起始滚动速度
                const baseSpeed = 0.3
                // 步长
                let count = 1;
                const _this = this
                const timer = setInterval(function () {

                    _this.init(count)
                    if (count == angel) {
                        clearInterval(timer)
                        if (typeof callback == "function") {
                            callback()
                        }
                    }

                    count = count + baseStep * (((angel - count) / angel) > baseSpeed ? baseSpeed : ((angel - count) / angel))
                    if (angel - count < 0.5) {
                        count = angel
                    }

                }, 25)
            }
        }

    }

    const showRedPrize = ref(false)
    const awardName = ref()
    const awardImg = ref()
    const router = useRouter();

    var wheelSurf: any
    // 初始化装盘数据 正常情况下应该由后台返回
    var initData = {
            "success": true,
             "list": [
                {
                    "id": 6,
                    "name": "88积分",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/Event/8493f1ae086a2a68efeb442b50339fbe.png",
                    "rank": 1,
                    "percent": 10
                },
                {
                    "id": 2,
                    "name": "办公套装",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/Event/a5f7e0d17bc8c33b15d0b6916202eb7d.png" ,
                    "rank": 5,
                    "percent": 10
                },
                {
                    "id": 3,
                    "name": "数据线",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/Event/742601ec1b1ec8337a3a6e7841f19fab.png",
                    "rank": 3,
                    "percent": 10
                },
                {
                    "id": 4,
                    "name": "鼠标垫",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/Event/80b57033e47b38780402b281217987e9.jpg",
                    "rank": 7,
                    "percent": 50
                },
                {
                    "id": 5,
                    "name": "66积分",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/Event/8493f1ae086a2a68efeb442b50339fbe.png",
                    "rank": 6,
                    "percent": 10
                },
                {
                    "id": 666,
                    "name": "养生壶",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/Event/ysh.png",
                    "rank": 2,
                    "percent": 10
                },
                {
                    "id": 777,
                    "name": "定制雨伞",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/Event/dzys.png",
                    "rank": 4,
                    "percent": 10
                },
                {
                    "id": 999,
                    "name": "体脂秤",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/Event/tzc.png",
                    "rank": 8,
                    "percent": 10
                },
            ]
        }

    // 计算分配获奖概率(前提所有奖品概率相加100%)
    function getGift() {
        var percent = Math.random() * 100
        var totalPercent = 0
        for (var i = 0, l = initData.list.length; i < l; i++) {
            totalPercent += initData.list[i].percent
            if (percent <= totalPercent) {
                console.log(initData.list[i]);
                return initData.list[i];
            }
        }
    }

    var list = {}
    var angel = 360 / initData.list.length
    // 格式化成插件需要的奖品列表格式
    for (var i = 0, l = initData.list.length; i < l; i++) {
        list[initData.list[i].rank] = {
            id: initData.list[i].id,
            name: initData.list[i].name,
            image: initData.list[i].image
        }
    }
    var throttle = true;


    onMounted(() => {

        // 定义转盘奖品
        wheelSurf = ($('#myCanvas') as any).WheelSurf({
            list: list, // 奖品 列表，(必填)
            outerCircle: {
                color: '#e74b44' // 外圈颜色(可选)
            },
            innerCircle: {
                color: '#f4ad26' // 里圈颜色(可选)
            },
            dots: ['#fff7af', '#fafffe'],
            disk: ['#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0'], //转盘颜色
            title: {
                color: '#5c1e08',
                font: '16px Arial'
            } // 奖品标题样式(可选)
        })
        // 初始化转盘
        wheelSurf.init()


        $("#start").on('click', function () {
            const token = localStorage.getItem("token");
            if (!token) {
              Modal({
                title: "温馨提示",
                content: "登录后才可以进行抽奖哦~",
                onClose: () => {
                  router.push({
                    name: "Login",
                    query: {
                      redirect: router.currentRoute.value.fullPath,
                    },
                  });
                },
              });
              return false;
            }
            var winData: any; // 正常情况下获奖信息应该由后台返回
            axios
              .post("/M/Activicy/SigninStart", 
                qs.stringify({
                  idstr: '1567322040152039426',
                  tag: 1,
                })
              )
              .then((res: any) => {
                // console.log(data);
                if (res.data.success) {
                    winData = res.data.data
                    throttle = false;
                    var count = 0
                    // 计算奖品角度
                    for (var key in list) {
                        if (list.hasOwnProperty(key)) {
                            if (winData.rewardNum == list[key].id) {
                                winData.image = list[key].image
                                break;
                            }
                            count++
                        }
                    }

                    // 转盘抽奖，
                    wheelSurf.lottery((count * angel + angel / 2), function () {
                        awardName.value = winData.rewardName
                        awardImg.value = winData.image
                        showRedPrize.value = true
                        throttle = true;
                    })

                } else {
                    Toast({
                      title: res.data.msg,
                      type: "error",
                      duration: 2000,
                    });
                }
              })
              .catch((err) => {
                Toast({
                      title: '执行错误，请联系管理员！',
                      type: "error",
                    });
              });
        });


       

        $("#qianButton").on('click', function () {
            const token = localStorage.getItem("token");
            if (!token) {
              Modal({
                title: "温馨提示",
                content: "登录后才可以签到哦~",
                onClose: () => {
                  router.push({
                    name: "Login",
                    query: {
                      redirect: router.currentRoute.value.fullPath,
                    },
                  });
                },
              });
              return false;
            }
            axios
              .post("/M/Activicy/todaySignin", 
                qs.stringify({
                  eventId: '1567322040152039426',
                  tag: 1,
                })
              )
              .then((res: any) => {
                if (res.data.success) {
                    Toast({
                      title: res.data.msg,
                      type: "success",
                      duration: 2000,
                    });
                    getQian();
                } else {
                    Toast({
                      title: res.data.msg,
                      type: "error",
                      duration: 2000,
                    });
                }
              })
              .catch((err) => {
                Toast({
                      title: '执行错误，请联系管理员！',
                      type: "error",
                    });
              });
        });



    })

     function checkQian(){
             const token = localStorage.getItem("token");
            if (!token) {
              Modal({
                title: "温馨提示",
                content: "登录后才可以签到哦~",
                onClose: () => {
                  router.push({
                    name: "Login",
                    query: {
                      redirect: router.currentRoute.value.fullPath,
                    },
                  });
                },
              });
              return false;
            }
            axios
              .post("/M/Activicy/todaySignin", 
                qs.stringify({
                  eventId: '1567322040152039426',
                  tag: 1,
                })
              )
              .then((res: any) => {
                if (res.data.success) {
                    Toast({
                      title: res.data.msg,
                      type: "success",
                      duration: 2000,
                    });
                    getQian();
                } else {
                    Toast({
                      title: res.data.msg,
                      type: "error",
                      duration: 2000,
                    });
                }
              })
              .catch((err) => {
                Toast({
                      title: '执行错误，请联系管理员！',
                      type: "error",
                    });
              });
        }

    function onClosePrize() {
        showRedPrize.value = false;
        router.push({
          name: "Prize"
        });
    }

    return {
        showRedPrize,
        onClosePrize,
        awardImg,
        awardName,
        isLogin,
        signCount,
        isQian,
        luckCount,
        checkQian
    }
  },
});
</script>

<style lang="scss" >



.sweepstakes_wrap {
    padding: 0.2rem 0;
    background: url("../assets/img/eventbg/signBg.jpg") no-repeat;
    background-size: 100%;
    // background-color: #BF131D;
    background-color: #B11720;
}

.sweepstakes_wrap h1 {
    text-align: center;
    line-height: 40px;
    color: #666666;
    font-size: 22px;
}

.sweepstakes_title {
    margin-top: 0.2rem;
}

.sweepstakes_title textarea {
    width: 100%;
    font-size: 0.28rem;
    padding: 0.1rem;
    border: 1px solid #808080;
}

.sweepstakes_title p,
.Sweepstakes_guize_title {
  font-size: 16.5px;
  font-weight: bold;
  position: relative;
  padding-left: 10px;
}

.sweepstakes_title p::after,
.Sweepstakes_guize_title::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  width: 3px;
  height: 17.5px;
  background: #df0024;
}

.sweepstakes_huida {
    display: block;
    line-height: 0.3rem;
    font-size: 0.3rem;
    margin-top: 0.2rem;
    color: rgb(102,102,102);
}

.sweepstakes_guan {
    display: block;
    margin-top: 0.2rem;
    font-weight: 600;
    font-size: 0.33rem;
    margin-bottom: 0.2rem;
    line-height: 0.6rem;
}

.sweepstakes_guan input {
    width: 0.8rem;
    height: 0.4rem;
    line-height: 0.4rem;
    border: none;
    border-bottom: 1px solid;
    outline: none;
    text-align: center;
}

.sweepstakes_title span {
    font-size: 0.25rem;
    display: inline-block;
}

.sweepstakes_title span a {
    color: #0072bb;
}

.sweepstakes_btn {
    display: inline-block;
    width: 100%;
    height: 0.9rem;
    background-color: #d64b4b;
    box-shadow: 0px 0px 0.2rem 0px rgba(0, 0, 0, 0.2);
    border-radius: 0.05rem;
    line-height: 0.9rem;
    text-align: center;
    color: #fff !important;
    font-size: 0.3rem;
}

.Sweepstakes_guize {
  margin-top: 20px;
  text-align: left;
  color: #666;
}

.Sweepstakes_guize_content {
  background: url("../assets/img/eventbg/signGuize.png") no-repeat;
  background-size: 100%;
  // height: 5.2rem;
  height: 8.5rem;
  // margin-bottom: 1rem;
  position: relative;
//   top: -40px;
}

.sweepstakes_content {
    text-align: center;
    position: relative;
    // padding: 0.4rem 0;
}

.Sweepstakes_start {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.75rem;
    margin-top: -0.75rem;
    cursor: pointer;
    width: 1.5rem;
}

.Sweepstakes_dibu {
    width: 100%;
}

.challengeWhellSelect {
    width: 1.6rem;
    height: 0.5rem;
    background: none;
    font-family: 'Microsoft YaHei';
}

.challengeWhellTitle {
    text-align: center;
    font-size: 0.26rem;
    line-height: 0.5rem;
}

.Sweepstakes_bottom_content {
    line-height: 0.6rem;
}

.Sweepstakes_bottom_content p a {
    text-decoration: underline;
    color: #2a2aca;
}

.startImg {
    text-align: center;
    margin-bottom:2rem;
}

.startImg img {
    width: 6rem;
}

.tishi_th {
    text-align: center;
    color: #fff;
    font-size: 14px;
}



.lottery-text {
    position: absolute;
    left: 11%;
    top: 49.5%;
    width: 80%;
    /* margin-left: -40%; */
    z-index: 20;
    color: #ec3f3f;
    font-size: 0.20rem;
    text-align: center;
}

#try-winnerIMG {
    width: 1.5rem;
    height: 1.3rem;
    position: absolute;
    top: -1.8rem;
    right: 1.25rem;
}

.act_title{
    width:100%;
    margin:0 auto;
    text-align: center;
    margin: 0.5rem 0 5.5rem 0;
}

.huodong {
    display: block;
    color: #f8e8ca;
    font-size: 15px;
    // padding: 5px 20px;
    padding: 10px 30px 5px 40px;
}

.huodong:first-child {
    // padding-top: 18px;
    padding-top: 110px;
    position: relative;
}
.activity_rules {
    position: absolute;
    top: 66px;
    margin-left: 108px;
    font-size: 16px;
}

.qian_detail{
    color:#f8e8ca;
    padding: 0.3rem 0.8rem 0.1rem 1rem;
    font-size: 0.58rem;
}
</style>